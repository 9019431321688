/* eslint-disable import/no-cycle */
/* eslint-disable react/no-danger */
import React from 'react';
import IllnessJPG from '@assets/images/illness.jpg';
import IllnessMobileJPG from '@assets/images/illness-mobile.jpg';
import { ContainerSmall } from '~/utils/styles/utils';
import HeaderFooterLayout from '~/components/molecules/HeaderFooterLayout/HeaderFooterLayout';
import { useTranslationContext } from '~/pages/migraene-kopfschmerzen';
import { useTranslationContext as useTranslationContextEn } from '~/pages/en/migraine-headache';

const MigrainePage: React.FC = () => {
  const t = useTranslationContext();
  const tEn = useTranslationContextEn();

  return (
    <>
      <HeaderFooterLayout
        desktopHero={IllnessJPG}
        mobileHero={IllnessMobileJPG}
        altHero="Modell eines Gehirns zur Krankheitserklärung von Migräne & Kopfschmerzen"
        enLink="/en/migraine-headache/"
        deLink="/migraene-kopfschmerzen/"
      >
        <ContainerSmall>
          <div
            dangerouslySetInnerHTML={{
              __html: t('MigraineContent1') || tEn('MigraineContent1'),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: t('MigraineContent2') || tEn('MigraineContent2'),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: t('MigraineContent3') || tEn('MigraineContent3'),
            }}
          />
        </ContainerSmall>
      </HeaderFooterLayout>
    </>
  );
};

export default MigrainePage;
